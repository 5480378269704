var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popupBox-wrap"},[_c('div',{staticClass:"popupPosition",on:{"click":function($event){return _vm.$emit('close')}}}),_c('div',{staticClass:"popupBox w-840"},[_vm._m(0),_c('div',{staticClass:"popupBox-body"},[_c('div',{staticClass:"editor-img-btn-box",on:{"click":function($event){return _vm.$refs.repImageInput.click()}}},[_c('div',{staticClass:"icon-img icon-editor-img"},[_vm._v("이미지")]),_c('span',[_vm._v("사진 첨부")])]),_c('input',{ref:"repImageInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/gif, image/jpg, image/jpeg, image/png"},on:{"change":function($event){return _vm.setImage($event)}}}),_c('div',{staticClass:"img-box"},[_c('cropper',{ref:"cropper",staticClass:"rep-image-cropper cursor-pointer",attrs:{"src":_vm.image,"canvas":{
              maxWidth: 1000,
              maxHeight: 0,
              minWidth: 800,
              minHeight: 550,
            },"stencil-size":{
              width: 800,
              height: 550
            },"stencil-props":{
              handlers: {},
              movable: false,
              resizable: false,
              scalable: false,
              aspectRatio: 16/11,
              maxWidth: 1000,
              width: 800,
              height: 550
            },"default-size":{
              width: 1000,
            },"width":"1000px","resizeImage":false,"transition":"false","image-restriction":"stencil"}})],1),(_vm.imageMsg)?_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.imageMsg))]):_vm._e(),_vm._m(1)]),_c('div',{staticClass:"popupBox-bottom"},[_c('button',{staticClass:"w-90 mr-10 btn btn-sm btn-outline color-6",on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$msg('cancel')))]),_c('button',{staticClass:"w-90 btn btn-sm fw-bold color-1",on:{"click":_vm.crop}},[_vm._v("등록")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popupBox-title btween"},[_c('div',[_vm._v("대표이미지")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"information-box"},[_c('div',{staticClass:"information-box-title"},[_vm._v("안내")]),_c('div',{staticClass:"information-contents"},[_c('ul',{staticClass:"dot-list"},[_c('li',[_vm._v("권장사이즈(800*550) / 이미지 형식 jpg, gif, png만 지원 / 이미지용량 10MB 이하")]),_c('li',[_vm._v("드래그 앤 드롭으로 대표이미지 위치를 지정해주세요")])])])])
}]

export { render, staticRenderFns }
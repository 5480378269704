import { Editor, EditorContent, BubbleMenu } from "@tiptap/vue-2"
import StarterKit from '@tiptap/starter-kit'
import Paragraph from '@tiptap/extension-paragraph'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import Highlight from "@tiptap/extension-highlight"
import Placeholder from '@tiptap/extension-placeholder'
import Link from '@tiptap/extension-link'
import DropCursor from '@tiptap/extension-dropcursor'
import HorizontalRule from "@/components/tiptap/horizontalRule"
import Underline from '@/components/tiptap/underline'
import ImageUpload from '@/components/tiptap/image/index'
import Youtube from '@/components/tiptap/youtube'

import youtubeVideoPopup from '@/components/tiptap/popup/youtubeVideoPopup'

export default {
  components: {
    EditorContent,
    BubbleMenu,
    youtubeVideoPopup,
  },
  data() {
    return {
      editor: null,
      isOpenSelect: {
        media: false,
        text: false,
        hr: false,
      },
      isYoutubeVideoPopup: false,
      linkUrl: null,
      isLinkActive: null,
      isOpenLinkPopup: false,
    }
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit.configure({
          paragraph: false,
          horizontalRule: false,
          dropcursor: false,
        }),
        Underline,
        TextStyle,
        Color,
        Highlight.configure({ multicolor: true }),
        Paragraph.extend({
          parseHTML() {
            return [{ tag: 'div', class: 'default-text' }]
          },
          renderHTML({ HTMLAttributes }) {
            return ['div', {class: 'default-text'}, 0]
            // return ['div', {class: 'default-text'}, 0] // ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
          },
        }),
        Placeholder.configure({
          emptyEditorClass: 'is-editor-empty',
          emptyNodeClass: 'is-empty',
          placeholder: '본문 내용을 입력해주세요.',
          showOnlyWhenEditable: true,
          showOnlyCurrent: true,
        }),
        Link.configure({
          openOnClick: false,
          linkOnPaste: false,
          validate: href => /^https?:\/\//.test(href),
          target: '_blank'
        }),
        DropCursor.configure({
          color: '#ffe1d8',
          width: 3,
        }),
        HorizontalRule,
        ImageUpload,
        Youtube,
      ],
      content: '',
      // autoFocus: 'start',
    })
  },
  methods: {
    toggleSelectMedia() {
      this.isOpenSelect.media = !this.isOpenSelect.media
    },
    closeSelectMedia() {
      this.isOpenSelect.media = false
    },
    toggleSelectText() {
      this.isOpenSelect.text = !this.isOpenSelect.text
    },
    closeSelectText() {
      this.isOpenSelect.text = false
    },
    toggleSelectHr() {
      this.isOpenSelect.hr = !this.isOpenSelect.hr
    },
    closeSelectHr() {
      this.isOpenSelect.hr = false
    },
    openYoutubeVideoPopup(){
      this.isYoutubeVideoPopup = true
    },
    closeYoutubeVideoPopup(){
      this.isYoutubeVideoPopup = false
    },
    toggleLinkPopup(type, attrs) {
      if (type === 'open') {
        if (this.isOpenLinkPopup) return false
        this.linkUrl = attrs.href;
        this.isOpenLinkPopup = true
      }
      else {
        this.linkUrl = null
        this.isOpenLinkPopup = false
      }
    },
    goTop(){
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
  },
}

import { mergeAttributes } from '@tiptap/core'
import { HorizontalRule } from "@tiptap/extension-horizontal-rule"
import {TextSelection} from "prosemirror-state"

export default HorizontalRule.extend({
  addAttributes() {
    return {
      class: {
        default: null,
        renderHTML: attributes => {
          return {
            class: attributes.class,
          }
        }
      }
    }
  },

  renderHTML({ HTMLAttributes }) {
    return ['hr', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },

  addCommands() {
    return {
      // setHorizontalRule: options => ({ state, dispatch }) => {
      //   const position = state.selection.$cursor ? state.selection.$cursor.pos : state.selection.$to.pos
      //   const div = state.schema.nodes.paragraph.create()
      //   const node = this.type.create(options)
      //   const transaction = state.tr.insert(position+1, node).insert(position+3, div)
      //   dispatch(transaction);
      // },
      setHorizontalRule: options => ({ chain }) => {
        return chain()
          .insertContent({ type: this.name, attrs: options })
          .command(({ tr, dispatch }) => {
            if (dispatch) {
              const { $to } = tr.selection
              const posAfter = $to.end()

              if ($to.nodeAfter) {
                tr.setSelection(TextSelection.create(tr.doc, $to.pos))
              }
              else {
                const node = $to.parent.type.contentMatch.defaultType?.create()
                if (node) {
                  tr.insert(posAfter, node)
                  tr.setSelection(TextSelection.create(tr.doc, posAfter))
                }
              }
              tr.scrollIntoView()
            }

            return true
          })
          .run()
      },
    }
  },
})
<template>
  <div>
    <div class="popupBox w-400 editor-save-list">
      <div class="popupBox-title btween">
        <div>총 <span class="color1">{{ list.length }}개</span>의 임시저장 글</div>
        <div class="btn-wrap cursor-pointer">
          <span v-if="list.length > 0" @click="confirmRemoveAllPopup">전체 삭제</span>
        </div>
      </div>
      <div class="popupBox-body full inner-scroll">
        <div v-if="!list || list.length === 0" class="noDataMessage2">{{ this.$msg('nodata') }}</div>

        <div v-for="item in list" :key="`temp-${item.id}`" class="basic-list-item cursor-pointer" :class="{ active : idx == item.id}">
          <div class="subject" @click="loadPopup(item.id)">{{ item.title }}</div>
          <div class="user-info">{{ $getDateFormat(item.tempDate, 'Y.m.d') }}</div>
          <div class="btn-delete" @click="confirmRemovePopup(item.id)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex"
export default {
  props: ['idx'],
  name: "tempSavePopup",
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.setList()
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    async setList() {
      const { result, list } = await this.$api.getTempBoardList()
      if (result === 'success' && list) this.list = list
    },
    loadPopup(id) {
      if (this.idx == id) { // 현재 페이지
        this.alertPopup(this.$msg('temp.here'))
        return false
      }
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('temp.load'),
        okay: 'CONFIRM',
        okayCallback: () => {
          this.setMsgPopup()
          this.$emit('save', id)
          this.close()
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
    confirmRemovePopup(id) {
      if (this.idx == id) { // 현재 페이지
        this.alertPopup(this.$msg('temp.here.remove'))
        return false
      }
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('confirm.remove'),
        okay: 'REMOVE',
        okayCallback: async() => {
          this.setMsgPopup()
          if (!id) return false
          const { result } = await this.$api.removeTempBoard({ id: id })
          if (result === 'success') {
            this.list = this.list.filter(l => l.id !== id)
            this.$emit('remove')
          }
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
    confirmRemoveAllPopup() {
      this.setMsgPopup({
        type: 'confirm',
        message: this.$msg('confirm.remove'),
        okay: 'REMOVE',
        okayCallback: async() => {
          this.setMsgPopup()
          const { result } = await this.$api.removeAllTempBoard({ id: this.idx })
          if (result === 'success') {
            this.list = this.list.filter(l => l.id == this.idx)
            this.$emit('remove')
          }
        },
        cancel: 'CLOSE',
        cancelCallback: () => this.setMsgPopup()
      })
    },
    close() {
      this.$emit('close')
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'OK',
        okayCallback: () => {
          this.setMsgPopup()
        }
      })
    },
  }
}
</script>

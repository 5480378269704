<template>
  <div>
    <div class="writeHeader">
      <div class="logo cursor-pointer" @click="goMain"><a><img src="@/assets/img/common/logo.png"></a></div>

      <div class="editor-btn-box">
        <div class="select-box" @click="toggleSelectMedia" v-click-outside="closeSelectMedia">
          <div class="editor-btn-img">
            <div class="icon-img icon-editor-img">이미지</div>
          </div>
          <div class="select-popup" v-if="isOpenSelect.media">
            <div class="editor-btn-img select-div" @click="$refs.imageInput.click()">
              <div class="icon-img icon-editor-img"></div><span>사진</span>
            </div>
            <div class="editor-btn-img select-div" @click="openYoutubeVideoPopup(true)">
              <div class="icon-img icon-editor-video"></div><span>영상</span>
            </div>
          </div>

          <input type="file" accept="image/gif, image/jpg, image/jpeg, image/png" ref="imageInput"
                 multiple @change="uploadEditorImage($event)" style="display:none">
        </div>

        <div class="link-bar"></div>

        <div class="select-box" @click="toggleSelectText" v-click-outside="closeSelectText">
          <div class="selected" :class="{'on': isOpenSelect.text}">
            <div class="editor-btn-text" v-if="editor && editor.isActive('heading') && editor.isActive('heading', { level: 2 })">제목</div>
            <div class="editor-btn-text" v-else-if="editor && editor.isActive('heading') && editor.isActive('heading', { level: 3 })">본문2</div>
            <div class="editor-btn-text" v-else>본문1</div>
          </div>

          <div class="select-popup" v-if="isOpenSelect.text">
            <div class="editor-btn-text select-div" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()">
              <div class="subject">제목</div>
            </div>
            <div class="editor-btn-text select-div" @click="editor.chain().focus().setParagraph().run()">
              <div class="bodyText1">본문1</div>
            </div>
            <div class="editor-btn-text select-div" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()">
              <div class="bodyText2">본문2</div>
            </div>
          </div>

        </div>

        <div class="link-bar"></div>

        <div :class="['editor-btn-font', { 'on': editor && editor.isActive('bold') }]" @click="editor.chain().focus().toggleBold().run()">
          <div class="icon-img icon-editor-bold">볼드</div>
        </div>
        <div :class="['editor-btn-font', { 'on': editor && editor.isActive('italic') }]" @click="editor.chain().focus().toggleItalic().run()">
          <div class="icon-img icon-editor-italic">기울임</div>
        </div>
        <div :class="['editor-btn-font', { 'on': editor && editor.isActive('underline') }]" @click="editor.chain().focus().toggleUnderline().run()">
          <div class="icon-img icon-editor-under">밑줄</div>
        </div>

        <div class="link-bar"></div>

        <div class="editor-btn-color" @click="setColor('#272727')">
          <div class="color-box bgcolor-9">검정</div>
        </div>
        <div class="editor-btn-color" @click="setColor('#FF693A')">
          <div class="color-box bgcolor-1">주황</div>
        </div>
        <div class="editor-btn-color" @click="setColor('#643BFF')">
          <div class="color-box bgcolor-3">보라</div>
        </div>

        <!--        editor.isActive('highlight', { color: 'red' }) }"-->
        <div class="editor-btn-color" @click="editor.chain().focus().toggleHighlight({ color: '#fff8b2' }).run()">
          <div class="color-box" style="border: 3px solid #fff8b2">배경1</div>
        </div>

        <div class="editor-btn-color" @click="editor.chain().focus().toggleHighlight({ color: '#ffe3c8' }).run()">
          <div class="color-box" style="border: 3px solid #ffe3c8">배경2</div>
        </div>

        <div class="link-bar"></div>

        <div class="editor-btn-link" @click="toggleLinkPopup('open', editor.getAttributes('link'))" v-click-outside="toggleLinkPopup">
          <div class="icon-img icon-editor-link">링크</div>
          <div v-if="isOpenLinkPopup" class="popupBox editor-link-input w-400">
            <div class="popupBox-body">
              <div><input type="text" placeholder="https:// 포함한 URL을 입력하세요." v-model="linkUrl" v-focus @keypress.enter="setLink"></div>
            </div>
            <div class="popupBox-bottom">
              <button v-if="editor.isActive('link')" @click="editor.commands.unsetLink()" class="w-90 mr-10 btn btn-sm btn-outline color-6" >링크해제</button>
              <button :class="['w-90 btn btn-sm', isLinkActive ? 'color-1' : 'color-6 btn-outline']" @click="setLink">확인</button>
            </div>
          </div>
        </div>

        <div class="link-bar"></div>

        <div class="select-box" @click="toggleSelectHr" v-click-outside="closeSelectHr">
          <div :class="['selected']">
            <div class="editor-btn-text">가로선</div>
          </div>
          <div class="select-popup" v-if="isOpenSelect.hr">
            <div class="editor-btn-text select-div flex-center" @click="editor.chain().focus().setHorizontalRule({class: 'short'}).run()">
              <div class="hr short"/>
            </div>
            <div class="editor-btn-text select-div" @click="editor.chain().focus().setHorizontalRule().run()">
              <div class="hr"/>
            </div>
          </div>
        </div>

        <div class="quick_top_btn" @click="goTop"><span></span></div>
      </div>

      <div class="save-submit-btn-box">
        <div class="save-btn-box" v-if="!isEdit">
          <div class="save-btn-inner">
            <div class="d-inline-block cursor-pointer" @click="tempSave(null)">
              <span class="btn-text">임시저장</span>
            </div>
            <div class="d-inline-block"><span class="btn-text"> ㅣ </span></div>
            <div class="d-inline-block" @click="openTempPopup" v-click-outside="closeTempPopup">
              <span class="btn-text cursor-pointer">{{ tempCount }}</span>
              <temp-save-popup v-if="isOpenTempPopup" :idx="idx" @close="closeTempPopup" @save="tempSave" @remove="setTempCount" />
            </div>
          </div>
        </div>
        <div class="submit-btn-box" @click="openPublishPopup" v-click-outside="closePublishPopup">
          <div class="btn color-1">발행신청</div>
          <publish-popup v-if="isOpenPublishPopup" :pCtgry="ctgry" :pPetCode="petCode" @close="closePublishPopup" @save="publishSave" />
        </div>
      </div>
    </div>

    <div class="inner-layout-800">
      <div class="writeP-thumb-box" :class="{ error : repMsg }">
        <div v-if="repImageUrl" class="img-box">
          <image-alt :src="repImageUrl" altType="detail"></image-alt>
          <div class="thumb-img-edite">
            <div class="btn btn-block btn-large" @click="openRepImagePopup">대표이미지 변경</div>
          </div>
        </div>
        <div v-else class="text-box">
          대표 이미지를 선택해주세요.
          <div class="fw-bold">*권장 사이즈 : 800 X 550</div>
          <div class="w-170 mt-30">
            <div class="btn btn-block btn-large color-10" @click="openRepImagePopup">대표이미지 등록</div>
          </div>
        </div>
      </div>
      <div v-if="repMsg" class="error-message">{{ repMsg }}</div>
    </div>
    <rep-image-popup v-if="isOpenRepImagePopup" @upload="repImageUpload" @close="closeRepImagePopup"/>

    <div class="inner-layout-800">
      <div class="writeP-subject-box">
        <input type="text" placeholder="제목을 입력해주세요." class="input-box" v-model="title" maxlength="50">
        <span class="subject-limit">{{ title.length }}/50</span>
      </div>
      <div v-if="titleMsg" class="error-message">{{ titleMsg }}</div>
    </div>

    <div v-if="editor" class="writeP-content-box editor-content-box" style="margin-top: 0;margin-bottom: 0">
      <bubble-menu :editor="editor" :tippy-options="{ zIndex: 10, duration: 400 }" v-if="editor">
        <transition name="fade">
          <div class="float-menu">
            <div class="editor-btn-font" :class="{ 'on': editor && editor.isActive('bold') }" @click="editor.chain().focus().toggleBold().run()">
              <div class="icon-img icon-editor-bold">볼드</div>
            </div>
            <div class="editor-btn-font" :class="{ 'on': editor && editor.isActive('italic') }" @click="editor.chain().focus().toggleItalic().run()">
              <div class="icon-img icon-editor-italic">기울임</div>
            </div>
            <div class="editor-btn-font" :class="{ 'on': editor && editor.isActive('underline') }" @click="editor.chain().focus().toggleUnderline().run()">
              <div class="icon-img icon-editor-under">밑줄</div>
            </div>

            <div class="link-bar"></div>

            <div class="editor-btn-color" @click="setColor('#272727')">
              <div class="color-box bgcolor-9">검정</div>
            </div>
            <div class="editor-btn-color" @click="setColor('#FF693A')">
              <div class="color-box bgcolor-1">주황</div>
            </div>
            <div class="editor-btn-color" @click="setColor('#643BFF')">
              <div class="color-box bgcolor-3">보라</div>
            </div>
            <div class="editor-btn-color" @click="editor.chain().focus().toggleHighlight({ color: '#fff8b2' }).run()">
              <div class="color-box" style="border: 3px solid #fff8b2">배경1</div>
            </div>
            <div class="editor-btn-color" @click="editor.chain().focus().toggleHighlight({ color: '#ffe3c8' }).run()">
              <div class="color-box" style="border: 3px solid #ffe3c8">배경2</div>
            </div>
          </div>
        </transition>
      </bubble-menu>

      <editor-content style="color: #333333" :editor="editor" placeholder="본문 내용을 입력해주세요."/>
    </div>

    <youtube-video-popup v-if="isYoutubeVideoPopup" @set="setYoutubeVideo" @close="closeYoutubeVideoPopup" />
  </div>
</template>

<script>
import Compressor from 'compressorjs'
import {mapActions} from "vuex"
import editorMixin from "@/mixins/editorMixin"
import leaveMixin from "@/mixins/leaveMixin"
import RepImagePopup from "@/components/board/RepImagePopup"
import TempSavePopup from "@/components/board/TempSavePopup"
import PublishPopup from "@/components/board/PublishPopup"

export default {
  components: {
    RepImagePopup,
    TempSavePopup,
    PublishPopup
  },
  mixins: [ editorMixin, leaveMixin ],
  data() {
    return {
      prevRoute: null,
      isEdit: false,
      idx: this.$route.name === 'tip/new' ? 0 : this.$route.params.idx,
      ctgry: {
        cateCode: null,
        categoryName: '선택하세요',
      },
      petCode: null,
      repImageData: null,
      repImageName: null,
      repImageUrl: '',
      title: '',
      tempCount: 0,
      isOpenTempPopup: false,
      isOpenPublishPopup: false,
      repMsg: null,
      titleMsg: null,
      editorImageIds: [],
      isFirstLoad: true,
      savedImageIds: [],
      savedContent: null,
      isOpenRepImagePopup:false,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm=> {
      vm.prevRoute= from.path
    })
  },
  beforeMount() {
    window.addEventListener('keydown', this.preventKeyDown)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.preventKeyDown)
  },
  watch: {
    linkUrl(v) {
      const expUrl = /(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi
      this.isLinkActive = expUrl.test(v);
    }
  },
  created() {
    this.setTempCount()
  },
  mounted() {
    if (this.idx) this.setItem()
    if (this.idx && this.prevRoute === '/mypage/posts') this.isEdit = true
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    preventKeyDown(event) {
      let className = event.target.className
      if (className.indexOf('ProseMirror-hideselection') > -1) {
        event.preventDefault()
        event.stopPropagation()
      }
    },
    uploadEditorImage(event) {
      if (!event) return false
      event.stopPropagation()
      event.preventDefault()

      let result = false
      this.msg = null
      let files = event.target.files || event.dataTransfer.files

      if (files.length > 0) {
        for (let file of files) {
          if (!/^image\//.test(file.type)) {
            this.$emit('openPopup', this.$msg('image.ext'))
            return false
          }

          let fileExt = this.$getExts(file.name, false)
          let isImg = this.$isImages(fileExt)
          if (isImg) {
            if (file.size < this.$MBtoB(10)) { // 10MB
              result = true;
            } else {
              this.$emit('openPopup', this.$msg('image.limit'))
            }
          }

          if (result) {
            let vm = this

            const img = new Image()
            img.src = URL.createObjectURL(file)

            img.onload = () => {
              const isCompressor = fileExt === 'gif' || !(img.width > vm.$imageMaxWidth || img.height > vm.imageMaxHeight) ? false : true

              if (isCompressor) {
                const options = {
                  maxWidth: vm.$imageMaxWidth,
                  maxHeight: vm.imageMaxHeight,
                  success: (_file) => {
                    if (_file.size > vm.$MBtoB(10)) { // 리사이징 했는데도 용량이 큰 경우
                      vm.alertPopup('image.limit')
                      return false
                    }
                    let reader = new FileReader()
                    reader.onload = () => {
                      let params = {
                        cateCode: 10,
                        name: _file.name,
                        data: reader.result
                      }
                      vm.uploadImage(params)
                    }
                    reader.readAsDataURL(_file)
                  },
                  error: (err) => console.log(err)
                }
                new Compressor(file, options)
              }
              else {
                let reader = new FileReader()
                reader.onload = function () {
                  let params = {
                    cateCode: 10,
                    name: file.name,
                    data: reader.result
                  }
                  vm.uploadImage(params)
                }
                reader.readAsDataURL(file)
              }
            }
          }

        }
      }
    },
    async uploadImage(params) {
      const { result, data } = await this.$api.uploadEditorImage(params)
      if (result === 'success') {
        this.editorImageIds.push(Number(data.atchIdx))
        this.editor.chain().focus().setImage({
          src: data.src,
          title: params.name,
          id: data.atchIdx,
        }).run()
      }
    },
    getEditorImageIds() {
      let arr = []
      let imgArr = document.getElementsByClassName("editorImage")
      for (let img of imgArr) arr.push(Number(img.id))
      return arr
    },
    setYoutubeVideo(data) {
      this.editor.chain().focus().setYoutube(data).run()
    },
    setColor(color) {
      // const attrs = this.editor.getAttributes('link')
      // if (attrs && !attrs.href) this.editor.chain().focus().setColor(color).run()
      this.editor.chain().focus().setColor(color).run()
    },
    setLink() {
      if (!this.isLinkActive) return false
      this.editor.commands.setLink({ href: this.linkUrl, target: '_blank' })
      this.toggleLinkPopup()
    },
    async setItem() {
      if (!this.idx) await this.$router.push('/mypage/posts');
      const { result, data } = await this.$api.getBoardDetail({ cateCode: 10, boIdx: this.idx })
      if (result === 'success') {
        if (data.src) this.repImageUrl = data.src
        this.title = data.title
        this.editor.commands.setContent(data.content)
        this.savedContent = data.content
        this.ctgry = {
          cateCode: data.cateCode ? data.cateCode : null,
          categoryName: data.categoryName ? data.categoryName : '선택하세요'
        }
        this.petCode = data.petCode
        this.editorImageIds = this.getImageIds(data.content)
        this.savedImageIds = this.getImageIds(this.savedContent)
      }
    },
    getImageIds(content) {
      let div = document.createElement('div')
      div.innerHTML = content
      let arr = []
      let imgArr =  div.getElementsByClassName("editorImage")
      for (let img of imgArr) arr.push(Number(img.id))
      return arr
    },
    repImageUpload({data, name, type}) {
      this.repImageData = data
      this.repImageName = name
      this.repImageUrl = data
    },
    openRepImagePopup(){
      document.body.style.overflow='hidden'
      this.isOpenRepImagePopup = true
    },
    closeRepImagePopup() {
      document.body.style.overflow='visible'
      this.isOpenRepImagePopup = false
    },
    async setTempCount() {
      const { result, count } = await this.$api.getTempBoardCount()
      if (result === 'success') this.tempCount = count
    },
    async checkTempCount(id) {
      if (id == 0) {
        const { result, count } = await this.$api.getTempBoardCount()
        if (result === 'success' && count >= 30) {
          this.alertPopup(this.$msg('temp.limit'))
          return false
        }
      }
      return true
    },
    getContentToSave() {
      const contentHtml = this.editor.getHTML()
      const content = contentHtml.replace(/\<p><div/gi, '<div').replace(/\<\/div><\/p>/gi, '</div>')
          .replace(/\<p><\/p><p><div/gi, '<div').replace(/\<\/div><\/p><p><\/p>/gi, '</div></p>')
      return content
    },
    async tempSave(loadId) {
      if (await this.checkTempCount(this.idx)) {
        const { result, data } = await this.$api.saveBoard({
          target: 'temp',
          id: this.idx,
          cateCode: null,
          petCode: null,
          repImageData: this.repImageData,
          repImageName: this.repImageName,
          title: this.title ? this.title : '제목 없음',
          content: this.getContentToSave(),
          editImg: this.getEditorImageIds(),
        });
        if (result === 'success') {
          this.tempCount = data.tempCount
          this.movePath = `/tip/${loadId ? loadId : data.id}/modify`
          this.alertPopup(this.$msg('ok.temp'))
        }
      }
    },
    validatePublish() {
      this.repMsg = null
      this.titleMsg = null
      if (!this.repImageUrl) {
        this.repMsg = this.$msg('repImage.check')
        return false
      }
      if (!this.title) {
        this.titleMsg = this.$msg('required.input')
        return false
      }
      return true
    },
    async publishSave(cateCode, petCode) {
      if (!this.validatePublish()) return false
      const { result } = await this.$api.saveBoard({
        target: 'publish',
        id: this.idx,
        cateCode: cateCode,
        petCode: petCode,
        repImageData: this.repImageData,
        repImageName: this.repImageName,
        title: this.title,
        content: this.getContentToSave(),
        editImg: this.getEditorImageIds(),
      });
      if (result === 'success') {
        this.movePath = '/mypage/posts'
        this.alertPopup(this.$msg('ok.publish'))
      }
    },
    openTempPopup() {
      this.isOpenTempPopup = true
    },
    closeTempPopup() {
      this.isOpenTempPopup = false
    },
    openPublishPopup() {
      if (!this.validatePublish()) return false
      this.isOpenPublishPopup = true
    },
    closePublishPopup() {
      this.isOpenPublishPopup = false
    },
    goMain() {
      location.href = '/'
    },
    alertPopup(msg) {
      this.setMsgPopup({
        type: 'alert',
        message: msg,
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
          if (this.movePath) location.href = this.movePath
        },
      })
    },
  },
}
</script>

<template>
  <div class="popupBox-wrap"><!-- scroll -->
    <div class="popupPosition"  @click="$emit('close')"></div>
    <div class="popupBox w-840">
      <div class="popupBox-title btween">
        <div>대표이미지</div>
      </div>
      <div class="popupBox-body">
        <div class="editor-img-btn-box" @click="$refs.repImageInput.click()">
          <div class="icon-img icon-editor-img">이미지</div>
          <span>사진 첨부</span>
        </div>
        <input type="file" accept="image/gif, image/jpg, image/jpeg, image/png" ref="repImageInput" @change="setImage($event)" style="display:none;">
        <div class="img-box">
          <cropper
            ref="cropper"
            class="rep-image-cropper cursor-pointer"
            :src="image"
            :canvas="{
              maxWidth: 1000,
              maxHeight: 0,
              minWidth: 800,
              minHeight: 550,
            }"
            :stencil-size="{
              width: 800,
              height: 550
            }"
            :stencil-props="{
              handlers: {},
              movable: false,
              resizable: false,
              scalable: false,
              aspectRatio: 16/11,
              maxWidth: 1000,
              width: 800,
              height: 550
            }"
            :default-size="{
              width: 1000,
            }"
            width="1000px"
            :resizeImage="false"
            transition="false"
            image-restriction="stencil"
          />
        </div>
        <div v-if="imageMsg" class="error-message">{{ imageMsg }}</div>
        
        <div class="information-box">
          <div class="information-box-title">안내</div>
          <div class="information-contents">
            <ul class="dot-list">
              <li>권장사이즈(800*550) / 이미지 형식 jpg, gif, png만 지원 / 이미지용량 10MB 이하</li>
              <li>드래그 앤 드롭으로 대표이미지 위치를 지정해주세요</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="popupBox-bottom">
        <button class="w-90 mr-10 btn btn-sm btn-outline color-6" @click="close">{{ $msg('cancel') }}</button>
        <button class="w-90 btn btn-sm fw-bold color-1" @click="crop">등록</button>
      </div>
    </div>
  </div>
</template>
<script>
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

function getMimeType(file, fallback = null) {
  const byteArray = new Uint8Array(file).subarray(0, 4);
  let header = '';
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case '89504e47':
      return 'image/png';
    case '47494638':
      return 'image/gif';
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      return 'image/jpeg';
    default:
      return fallback;
  }
}

export default {
  components: { Cropper },
  data(){
    return {
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },
      image: null,
      imageType: null,
      imageName: null,
      imageMsg: null,
    }
  }, 
  methods:{
    setImage(event) {
      this.imageMsg = null
      let result = false;

      let files = event.target.files || event.dataTransfer.files;
      if (files.length > 0) {
        const file = files[0]
        if (this.image) URL.revokeObjectURL(this.image)

        if (!/^image\//.test(file.type)) {
          this.imageMsg = this.$msg('image.ext')
          return false
        }

        let fileExt = this.$getExts(file.name, false);
        let isImg = this.$isImages(fileExt);
        if (isImg) {
          if (file.size < this.$MBtoB(10)) { // 10MB
            result = true;
          } else {
            this.imageMsg = this.$msg('image.limit')
          }
        }

        if (result) {
          let vm = this
          const reader = new FileReader()
          reader.onload = (e) => {
            let image = new Image()
            image.src = URL.createObjectURL(file) // reader.result
            image.onload = function() {
              if (image.width < 800) {
                vm.imageMsg = vm.$msg('image.rep.check')
                e.target.files = null
              }
              else if (image.height < 550) {
                vm.imageMsg = vm.$msg('image.rep.check')
                e.target.files = null
              }
              else {
                const blob = URL.createObjectURL(file)
                vm.image = blob
                vm.imageName = file.name
                vm.imageType = getMimeType(e.target.result, file.type)
              }
            }
          }
          reader.readAsArrayBuffer(file)
        }
      }
    },
    crop() {
      const { coordinates, canvas, } = this.$refs.cropper.getResult()
      this.imageMsg = null
      if (!canvas) {
        this.imageMsg = this.$msg('repImage.check')
        return false
      }

      this.coordinates = coordinates
      const params = {
        data: canvas.toDataURL(),
        name: this.imageName,
        type: this.imageType
      }
      this.$emit('upload', params)
      this.$emit('close')
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
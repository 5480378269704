<template>
  <node-view-wrapper>
    <div :class="['content-img-box', {'ProseMirror-selectednode': isShowOutLine}]"
         @click="toggleOutLine('open')" v-click-outside="toggleOutLine"
         contenteditable="false" draggable="true" data-drag-handle>
      <img class="editorImage" :src="src" :alt="alt" :title="title"
           :id="id" :tagId="tagId" :tagTop="tagTop" :tagLeft="tagLeft" :tagColor="tagColor" :tagTitle="tagTitle"
           :tagPrice="tagPrice" :tagUrl="tagUrl" :tagSrc="tagSrc" :tagPosition="tagPosition"
      />

      <button class="trash" @click="removeEditorImage"/>
      <template v-if="tagId > 0">
        <button :id="`tag-${id}`" class="tag" :value="src" @click="openPopup"/>
        <div :style="`left: ${tagLeft}%; top: ${tagTop}%; position: absolute;`" id="tag-div">
          <div :class="`tag-icon ${tagColor}`" @click="toggleTagInfo" v-click-outside="closeTagInfo">
            <div class="dot"/>
            <div v-if="isOpenTagInfo" @click="goUrl" :class="`tag-product-box cursor-pointer ${tagPosition}`">
              <div class="product-img"><img :src="tagSrc"/></div>
              <div class="product-info">
                <strong class="tag-title">{{ tagTitle }}</strong>
                <span class="tag-price">{{ Number(tagPrice).numberFormat() }}원</span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </node-view-wrapper>
</template>
<script>
import { Editor, NodeViewWrapper, nodeViewProps } from "@tiptap/vue-2"
import { mapActions } from "vuex"

export default {
  components: {
    NodeViewWrapper,
  },
  props: nodeViewProps,
  data() {
    return {
      isOpenTagInfo: false,
      isShowOutLine: null,
    }
  },
  computed: {
    id: function() {
      return this.node && this.node.attrs.id
    },
    src: function() {
      return this.node && this.node.attrs.src
    },
    alt: function() {
      return this.node && this.node.attrs.alt
    },
    title: function() {
      return this.node && this.node.attrs.title
    },
    tagId: function() {
      return this.node && this.node.attrs.tagId
    },
    tagTop: function() {
      return this.node && this.node.attrs.tagTop
    },
    tagLeft: function() {
      return this.node && this.node.attrs.tagLeft
    },
    tagColor: function() {
      return this.node && this.node.attrs.tagColor
    },
    tagTitle: function() {
      return this.node && this.node.attrs.tagTitle
    },
    tagPrice: function() {
      return this.node && this.node.attrs.tagPrice
    },
    tagUrl: function() {
      return this.node && this.node.attrs.tagUrl
    },
    tagSrc: function() {
      return this.node && this.node.attrs.tagSrc
    },
    tagPosition: function() {
      return this.node && this.node.attrs.tagPosition
    },
  },
  methods: {
    ...mapActions({
      setOpenProductTagPopupWithImgId: 'productTag/setOpenProductTagPopupWithImgId',
    }),
    toggleOutLine(type) {
      this.isShowOutLine = type === 'open' ? true : false
    },
    removeEditorImage() {
      this.$el.parentElement.remove()
    },
    openPopup() {
      const pos = this.getPos()
      this.editor.commands.setNodeSelection(pos);
      this.editor.state.tr.setNodeMarkup(pos, null, {cursorPos: pos}, null)
      this.setOpenProductTagPopupWithImgId(this.id)
    },
    goUrl() {
      window.open(`${this.tagUrl}`)
    },
    toggleTagInfo() {
      this.isOpenTagInfo = !this.isOpenTagInfo
    },
    closeTagInfo() {
      this.isOpenTagInfo = false
    },
  },
};
</script>
export default {
  data() {
    return {
      movePath: null,
    }
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.unLoadEvent)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.unLoadEvent)
  },
  beforeRouteLeave(to, form, next) {
    const answer = window.confirm(this.$msg('confirm.leave'))
    if (answer) {
      next()
    }
    else {
      next(false)
    }
  },
  methods: {
    unLoadEvent(event) {
      if (!this.movePath) {
        event.preventDefault()
        event.returnValue = ""
      }
    },
  }
}

<template>
  <div class="popupBox w-500 editor-submit-popup">
    <div class="popupBox-title btween">
      <div>기본 정보 입력</div>
    </div>
    <div class="popupBox-body">
      <dl>
        <dt>카테고리<span class="color1">*</span></dt>
        <dd>
          <div class="select-box">
            <div class="select-text" @click="toggleFilter" :class="{ active : isOpenFilter }" v-click-outside="closeFilter">{{ ctgry.categoryName }}</div>
            <div class="select-list" :class="{ active : isOpenFilter }">
              <div class="select-list-box">
                <p v-for="item in ctgryList" :key="`ctgry-${item.cateCode}`" @click="setCtgry(item)">{{ item.categoryName }}</p>
              </div>
            </div>
          </div>
          <div v-if="ctgryMsg" class="error-message">{{ ctgryMsg }}</div>
        </dd>
      </dl>

      <dl>
        <dt>펫 구분<span class="color1">*</span></dt>
        <dd>
          <div class="label-inline">
            <label><input type="radio" name="type" @change="setType(10)" :checked="setChecked(10)"><span class="input-icon"></span>강아지</label>
            <label><input type="radio" name="type" @change="setType(20)" :checked="setChecked(20)"><span class="input-icon"></span>고양이</label>
          </div>
          <div v-if="typeMsg" class="error-message">{{ typeMsg }}</div>
        </dd>
      </dl>

      <div class="add-info-round-box">
        ㆍ작성해 주신 게시글은 꼬랩 에디터 검수를 통해 오픈여부가 결정됩니다.
      </div>
    </div>

    <div class="popupBox-bottom">
      <button class="w-90 mr-10 btn btn-sm btn-outline color-6" @click="$emit('close')">취소</button>
      <button class="w-90 btn btn-sm fw-bold color-1" @click="save">발행</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "publishPopup",
  props: ['pCtgry', 'pPetCode'],
  data() {
    return {
      isOpenFilter: false,
      ctgry: {
        cateCode: this.pCtgry.cateCode,
        categoryName: this.pCtgry.categoryName,
      },
      petCode: this.pPetCode,
      ctgryList: [],
      ctgryMsg: null,
      typeMsg: null,
    }
  },
  created() {
    this.getCtgry();
  },
  methods: {
    async getCtgry() {
      const { result, data } = await this.$api.getCtgry({ cate: 10 });
      if (result === 'success' && data.length > 0) this.ctgryList = data
    },
    setChecked(code) {
      if (this.petCode == code) return true
      return false
    },
    setCtgry(item) {
      this.ctgry = item
    },
    setType(code) {
      this.petCode = code
    },
    toggleFilter() {
      this.isOpenFilter = !this.isOpenFilter
    },
    closeFilter() {
      this.isOpenFilter = false
    },
    save() {
      this.ctgryMsg = null
      this.typeMsg = null
      if (!this.ctgry.cateCode) {
        this.ctgryMsg = this.$msg('required.select')
        return false
      }
      if (!this.petCode) {
        this.typeMsg = this.$msg('required.select')
        return false
      }
      this.$emit('save', this.ctgry.cateCode, this.petCode)
    }
  }
}
</script>

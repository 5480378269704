import { Node, mergeAttributes } from '@tiptap/core'

export default Node.create({
  name: 'video',
  group: 'inline',
  inline: true,
  selectable: true,
  draggable: true,
  atom: true,

  addAttributes() {
    return {
      id: {},
      src: {
        default: null
      },
    }
  },

  parseHTML: [{
    tag: 'iframe[id]',
    getAttrs: dom => ({
      id: dom.getAttribute('id'),
      src: dom.getAttribute('src')
    })
  }, 0],

  renderHTML({ HTMLAttributes }) {
    // let div = document.createElement('div')
    // div.className = 'content-img-box video'
    // div.className = 'content-video-box video'
    let iframe = document.createElement('iframe')
    iframe.setAttribute('id', HTMLAttributes.id)
    iframe.setAttribute('src', `https://www.youtube.com/embed/${HTMLAttributes.id}`)
    iframe.setAttribute('frameborder', "0")
    iframe.setAttribute('allow', "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture")
    iframe.setAttribute('allowfullscreen', "")
    // div.appendChild(iframe)
    return ['div', iframe]
  },

  addCommands() {
    return {
      // setYoutube: options => ({ state, dispatch, commands }) => {
      //   return commands.insertContent({
      //     type: this.name,
      //     attrs: options,
      //   })
      // }
      setYoutube: options => ({ state, dispatch, commands }) => {
        const { selection, schema } = state
        const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos
        const div = schema.nodes.paragraph.create()
        const node = this.type.create(options)
        const transaction = state.tr.insert(position+1, node).insert(position+3, div)
        dispatch(transaction);
      },
    }
  },
});

// import { Node, mergeAttributes } from '@tiptap/core'
//
// export default Node.create({
//   name: 'iframe',
//
//   group: 'block',
//
//   atom: true,
//
//   addOptions() {
//     return {
//       allowFullscreen: true,
//       HTMLAttributes: {
//         class: 'iframe-wrapper',
//       },
//     }
//   },
//
//   addAttributes() {
//     return {
//       id: {},
//       src: {
//         default: null,
//       },
//       frameborder: {
//         default: 0,
//       },
//       allowfullscreen: {
//         default: this.options.allowFullscreen,
//         parseHTML: () => this.options.allowFullscreen,
//       },
//     }
//   },
//
//   parseHTML() {
//     return [{
//       tag: 'iframe',
//     }]
//   },
//
//   renderHTML({ HTMLAttributes }) {
//     return ['div', this.options.HTMLAttributes, ['iframe', HTMLAttributes]]
//   },
//
//   addCommands() {
//     return {
//       setYoutube: options => ({ tr, dispatch }) => {
//         const { selection } = tr
//         const node = this.type.create(options)
//
//         if (dispatch) {
//           tr.replaceRangeWith(selection.from, selection.to, node)
//         }
//
//         return true
//       }
//     }
//   },
// });
<template>
  <div class="popupBox-wrap">
    <div class="popupPosition" @click="close"></div>
    <div class="popupBox w-600">
      <div class="popupBox-title">랜선집사</div>
      <div class="popupBox-body">
        <div class="contents-box-title">
          <div>유튜브 URL입력 <strong class="color-1">*</strong></div>
        </div>
        <div>
          https://youtu.be/<input-field class="d-inline-block underLineInput w-150" v-model="id" :errorMessage="idMsg" maxLength="30" placeholder="XXXXXXXXXXX"/>
        </div>
        <div class="information-box">
          <div class="information-box-title">안내</div>
          <div class="information-contents">
            <ul class="dot-list">
              <li>유튜브 링크만 사용 가능합니다.</li>
              <li>유튜브 공유하기 링크를 입력해주세요. ex) https://youtu.be/sample</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="popupBox-bottom">
        <button class="w-90 mr-10 btn btn-sm btn-outline color-6" @click="close">닫기</button>
        <button class="w-90 mr-10 btn btn-sm color-1" @click="save">등록</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // props: ['commands'],
  data(){
    return{
      id: null,
      idMsg: null,
    }
  },
  methods:{
    save() {
      this.idMsg = null
      if (!this.id) {
        this.idMsg = this.$msg('required.input')
        return false
      }
      // const data = {
      //   command: this.commands,
      //   data: {
      //     id: this.id
      //   }
      // };
      // this.$emit('set', data)
      this.$emit('set', { id: this.id })
      this.$emit('close')
    },
    close() {
      this.$emit('close')
    },

  }

}
</script>
